import React, { useContext, useEffect, useState } from 'react';
import { useParam } from '../../../../../hooks/useParam';
import { getParkAreaPrimaryColor } from '../../../../../data/parkAreas';
import { useWaitingTimes } from '../../../../../hooks/useWaitingTimes';
import { Ride, RideStateType } from '../../../../../types';
import { RideStateComponent } from '../../../../ride/RideStateComponent';
import { getRideAreaIDS } from '../../../../../utils/getRideAreaIDs';
import { ErrorDisplay } from '../../../../ride/ErrorDisplay';
import { ThemeContext } from '../../../../../context/ThemeContext';
import { getRideImage } from '../../../../../utils/getRideImage';
import { LocalizationContext } from '../../../../../context/LocalizationContext';
import { getLocalization } from '../../../../../utils/getLocalization';
import { TbInfoCircle } from 'react-icons/tb';
/**
 * Template for waiting time screen in table format.
 * Rides are displayed in two columns (or in a single one when orientation isn't landscape), with their name, location, park map number, wait time and status.
 */
export const WaitTimeScreenTable = ({ orientation = 'landscape', scale = '' }) => {
    const isTestMode = useParam('isTestMode') === 'true';
    const isStaging = useParam('isStaging') === 'true';
    const sortMode = 'waitTime';
    const ridesQuery = useWaitingTimes({
        isTestMode,
        isStaging,
        sortMode,
        endpoint: 'cawh',
        tags: ['wh_screen_table_park']
    });
    const theme = useContext(ThemeContext);

    return (
        <>
            {ridesQuery.isSuccess && (
                <div className={`${theme === 'halloween' && 'text-white'}`}>
                    <div className={`flex justify-center`}>
                        <div
                            className={`${orientation === 'landscape' && `grid-cols-2`} ${
                                ridesQuery.data.length < 9 && `mt-[50px]`
                            } inline-grid gap-x-16 ${scale}`}
                        >
                            {ridesQuery.data.map((ride: Ride) => (
                                <Attraction
                                    key={ride.name}
                                    rideId={ride.id}
                                    rideThroughput={ride.throughput}
                                    rideTags={ride.tags}
                                    areaName={ride.location}
                                    rideName={ride.name}
                                    rideNameLocalized={ride.nameLocalized}
                                    rideOpeningTime={ride.openingTime}
                                    rideImage={getRideImage(ride, theme)}
                                    rideStatus={{
                                        waitTimeMins: ride.waitTimeMins,
                                        state: ride.state
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {ridesQuery.isError && <ErrorDisplay />}
        </>
    );
};

const backgroundPositions = {
    'YOY Thrill': '60% 0%',
    'YOY Chill': '60% 0%'
};

const Attraction = ({
    rideName,
    rideNameLocalized,
    rideId,
    rideThroughput,
    rideTags,
    rideImage,
    areaName,
    rideStatus,
    rideOpeningTime
}: {
    rideName: string;
    rideNameLocalized: string;
    rideId: string;
    rideThroughput: number;
    rideTags: string[];
    rideImage: string;
    areaName: string;
    rideOpeningTime: string | null;
    rideStatus: { state: RideStateType; waitTimeMins: number };
}) => {
    const theme = useContext(ThemeContext);
    const currentLanguage = useContext(LocalizationContext);
    const [areaRideId, setAreaRideId] = useState<string>('');
    const [areaId, setAreaId] = useState<string>('?');

    useEffect(() => {
        console.log(rideTags);
        const locations = getRideAreaIDS(rideTags);
        setAreaRideId(locations[0]?.areaRideId ?? '?');
        setAreaId(locations[0]?.areaId ?? '?');
    }, [rideTags]);

    return (
        <div className="w-[640px] flex mb-4 border-b-[1px] pb-4 items-center">
            <div
                // @ts-ignore
                style={{ backgroundImage: `url(${rideImage})`, backgroundPosition: backgroundPositions[rideName] }}
                className={`bg-cover w-16 h-16 mr-6 rounded-full bg-slate-200 justify-self-center relative`}
            >
                <span
                    style={{ backgroundColor: getParkAreaPrimaryColor('cawh', areaId) }}
                    className={`text-white scale-90 w-6 text-sm flex items-center justify-center h-6 text-center font-walibi absolute bottom-[6px] right-[-4px]`}
                >
                    <p>{parseInt(areaRideId)}</p>
                </span>
            </div>
            <div>
                {rideTags.includes('wh_yoy') ? (
                    <div className="flex items-center gap-2 ">
                        {rideName === 'YOY Thrill' && (
                            <h1 className="font-walibi text-3xl uppercase flex items-center gap-4">
                                <img src="/yoy_thrill.png" alt="yoy" className="h-8" /> <span className="text-yoythrill">Thrill</span>
                            </h1>
                        )}

                        {rideName === 'YOY Chill' && (
                            <h1 className="font-walibi text-3xl uppercase flex items-center gap-4">
                                <img src="/yoy_chill.png" alt="yoy" className="h-8" /> <span className="text-yoychill">Chill</span>
                            </h1>
                        )}
                    </div>
                ) : (
                    <h1 className="font-walibi text-2xl uppercase">{rideNameLocalized}</h1>
                )}
                <div className={`text-xl font-avenir items-center  flex ${theme === 'halloween' && `text-white`}`}>
                    <div className="flex gap-2 items-center">
                        {rideTags.includes('wh_screen_info_banner') ? (
                            <p className="text-sm bg-blue-100 rounded-full text-blue-500 font-avenir_black  py-1 pl-2 pr-4 flex gap-2 items-center">
                                <TbInfoCircle className="text-base" />
                                {getLocalization('virtual_queue', currentLanguage)}
                            </p>
                        ) : (
                            areaName !== 'YOY' && (
                                <div className="flex gap-4">
                                    <p className={`${theme === 'halloween' ? 'text-gray-300' : `text-gray-600`}`}>{areaName}</p>
                                    {/* <RideStateTrains rideState={rideStatus.state} rideTags={rideTags} rideNoTrains={0} rideId={rideId} /> */}
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
            <div className="ml-auto">
                {rideStatus.state !== 'open' ? (
                    <div
                        className={`text-center text-xl font-avenir_black 
                        ${theme === 'halloween' ? `text-gray-100 bg-gray-800` : `text-gray-800 bg-gray-100`}
                    py-2 px-4 rounded-full`}
                    >
                        <RideStateComponent
                            waitTimeMins={rideStatus.waitTimeMins}
                            tags={rideTags}
                            state={rideStatus.state}
                            openingTime={rideOpeningTime}
                        />
                    </div>
                ) : (
                    <p
                        className={`mb-2 text-2xl font-avenir_black text-center  ${
                            theme !== 'halloween' && 'shadow-md'
                        } shadow-green-50 py-2 px-4 rounded-full mt-2
                        
                        ${theme === 'halloween' ? `text-green-200 bg-green-800` : `text-green-800 bg-green-100`}
                        
                        `}
                    >
                        {rideStatus.waitTimeMins < 5 ? `Open` : <span className="text-2xl">{rideStatus.waitTimeMins} min</span>}
                    </p>
                )}
            </div>
        </div>
    );
};
