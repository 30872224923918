export const parkAreas: {
    [park: string]: { [area: string]: { primaryColor: string; label: string; lines?: string[] } };
} = {
    cawh: {
        '01': {
            primaryColor: '#e42015',
            label: 'Main Street',
            lines: ['Main Street']
        },
        '02': {
            primaryColor: '#ef7c14',
            label: 'Exotic',
            lines: ['Exotic']
        },
        '03': {
            primaryColor: '#19418e',
            label: 'Speed Zone',
            lines: ['Speed Zone']
        },
        '04': {
            primaryColor: '#391315',
            label: 'Speed Zone - OFF ROAD',
            lines: ['Speed Zone', 'OFF ROAD']
        },
        '05': {
            primaryColor: '#71b532',
            label: 'Wilderness',
            lines: ['Wilderness']
        },
        '06': {
            primaryColor: '#fabb15',
            label: 'Zero Zone',
            lines: ['Zero Zone']
        },
        '07': {
            primaryColor: '#de4893',
            label: 'Walibi Play Land',
            lines: ['Walibi', 'Play Land']
        },
        '08': {
            primaryColor: '#3d8dcd',
            label: 'Play Ground',
            lines: ['Play Ground']
        },
        '09': {
            primaryColor: '#504395',
            label: 'YOY',
            lines: ['YOY']
        }
    }
};

export const getParkAreaPrimaryColor = (park: string, areaId: string): string => {
    if (!parkAreas[park] || !parkAreas[park][areaId]) return '#000000';
    return parkAreas[park][areaId].primaryColor;
};
