import { useContext } from 'react';
import { getLocalization } from '../../utils/getLocalization';
import { LocalizationContext } from '../../context/LocalizationContext';
import moment from 'moment';
import 'moment-timezone';

interface RideStateComponentProps {
    state: string;
    openingTime?: string | null;
    tags?: string[];
    waitTimeMins?: number;
}

export const RideStateComponent = ({ state, openingTime, tags, waitTimeMins }: RideStateComponentProps) => {
    const currentLanguage = useContext(LocalizationContext);
    let localization = getLocalization(openingTime && state === 'full_and_closed' ? 'openingTime' : state, currentLanguage);
    if (tags && tags.includes('wh_garantuee_ticket') && state === 'full' && waitTimeMins === 1)
        localization = getLocalization('full_garantuee_ticket', currentLanguage);
    // if (state !== 'open' && tags && tags.includes('wh_countdown')) {
    //     // @ts-ignore
    //     const targetDate = moment.tz('2025-04-05 14:00:00', 'Europe/Amsterdam');
    //     const now = moment();
    //     const duration = moment.duration(targetDate.diff(now));
    //     const days = duration.days();
    //     const hours = duration.hours();
    //     const minutes = duration.minutes();
    //     let string = '';
    //     if (days > 0) string += `${days} days, `;
    //     if (hours > 0) string += `${hours} hours, `;
    //     if (minutes > 0) string += `${minutes} minutes`;
    //     if (days !== 0 && hours !== 0 && minutes !== 0) localization = string;
    // }
    if (openingTime) localization = localization.replace('{time}', moment.parseZone(openingTime).format('HH:mm'));
    return localization;
};
