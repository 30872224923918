import React, { useContext } from 'react';
import { useWaitingTimes } from '../../../../../hooks/useWaitingTimes';
import { useParam } from '../../../../../hooks/useParam';
import { RideStateComponent } from '../../../../ride/RideStateComponent';
import { Ride } from '../../../../../types';
import { ErrorDisplay } from '../../../../ride/ErrorDisplay';
import { ThemeContext } from '../../../../../context/ThemeContext';
import Snowfall from 'react-snowfall';
import { getRideImage } from '../../../../../utils/getRideImage';
import { getLocalization } from '../../../../../utils/getLocalization';
import { LocalizationContext } from '../../../../../context/LocalizationContext';
import { Clock } from '../../../../Clock';

/**
 * Waiting time screen with large ride images and overlay with ride name and waittime/status.
 * Is currently live at the Guest Service location on a big TV screen.
 */
export const WaitTimeScreenGridVideo = () => {
    const theme = useContext(ThemeContext);
    const isTestMode = useParam('isTestMode') === 'true';
    const isStaging = useParam('isStaging') === 'true';
    const ridesQuery = useWaitingTimes({
        isTestMode,
        isStaging,
        endpoint: 'cawh',
        skipNotOperational: true,
        tags: ['wh_screen_grid_video'],
        sortMode: 'waitTimeReversed'
    });
    return (
        <>
            {ridesQuery.isSuccess && (
                <div>
                    <div className={`grid w-[1920px] h-[1080px] bg-black `}>
                        {theme === 'halloween' && (
                            <div style={{ zIndex: 10 }}>
                                <Snowfall />
                            </div>
                        )}
                        <div className="grid grid-cols-[1314px_607px] relative">
                            <div className="absolute right-0 text-white z-10 py-8 px-12 drop-shadow-[0_1px_4px_rgba(0,0,0,0.5)]">
                                <Clock fontSize="text-4xl " />
                            </div>
                            <div className={`grid grid-cols-3`}>
                                {ridesQuery.data.map((ride: Ride) => (
                                    <div>
                                        <Attraction
                                            openingTime={ride.openingTime}
                                            name={ride.name}
                                            nameLocalized={ride.nameLocalized}
                                            rideTags={ride.tags}
                                            image={getRideImage(ride, theme)}
                                            waitTimeMins={ride.waitTimeMins}
                                            state={ride.state}
                                        />
                                    </div>
                                ))}
                            </div>
                            <video
                                src={
                                    theme === 'halloween'
                                        ? `https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/video-hfn.mp4?alt=media`
                                        : `https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/video.mp4?alt=media`
                                }
                                className="h-[1080px] w-[607px]"
                                autoPlay
                                loop
                                muted
                            />
                        </div>
                    </div>
                </div>
            )}
            {ridesQuery.isError && <ErrorDisplay />}
        </>
    );
};

const Attraction = ({
    name,
    nameLocalized,
    image,
    waitTimeMins,
    rideTags,
    state,
    openingTime,
    rideWithLights = false
}: {
    name: string;
    nameLocalized: string;
    image: string;
    rideTags: string[];
    waitTimeMins: number;
    state: string;
    openingTime: string | null;
    rideWithLights?: boolean;
}) => {
    const currentLanguage = useContext(LocalizationContext);
    const positions = {
        Condor: '0% 60%',
        'Crazy River': '0% 80%',
        'El Rio Grande': '0% 70%',
        'Space Shot': '0% 100%',
        Untamed: '0% 80%',
        'Xpress: Platform 13': '0% 20%',
        Goliath: '0% 20%',
        'Lost Gravity': '0% 30%',
        'Speed of Sound': '0% 30%'
    };
    return (
        <div
            className="h-[270px] relative"
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                // @ts-ignore
                backgroundPosition: `${positions[name] ? positions[name] : `center`}`,
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className="absolute w-full h-full bg-gradient-to-t from-black via-transparent to-transparent" />
            <div className="flex flex-col h-full py-8 px-6 text-white relative">
                {(rideTags.includes('wh_yoy') || name === 'Eat My Dust') && (
                    <p className="drop-shadow-[0_1px_4px_rgba(0,0,0,1)] shadow-sm font-avenir italic">
                        {getLocalization('fast_lane_exclusion_warning', currentLanguage)}
                    </p>
                )}
                <div className="font-walibi uppercase text-3xl mt-auto mb-1 drop-shadow-[0_4px_1px_rgba(0,0,0,0.25)]">
                    {rideTags.includes('wh_yoy') ? (
                        <div className="flex items-center gap-2 ">
                            {name === 'YOY Thrill' && (
                                <h1 className="flex items-center gap-2">
                                    <img src="/yoy_thrill_white.png" alt="yoy" className="h-7" /> <span className=" text-3xl">Thrill</span>
                                </h1>
                            )}
                            {name === 'YOY Chill' && (
                                <h1 className="flex items-center gap-2">
                                    <img src="/yoy_chill_white.png" alt="yoy" className="h-7" /> <span className=" text-3xl">Chill</span>
                                </h1>
                            )}
                        </div>
                    ) : (
                        <h1 className="">{nameLocalized}</h1>
                    )}
                </div>

                {state === 'open' ? (
                    <p className="font-avenir_black text-2xl leading-5">{waitTimeMins < 5 ? `Open` : `${waitTimeMins} min`}</p>
                ) : (
                    <p className="font-avenir_black text-2xl  leading-5">
                        <RideStateComponent tags={rideTags} waitTimeMins={waitTimeMins} state={state} openingTime={openingTime} />
                    </p>
                )}
            </div>
        </div>
    );
};
