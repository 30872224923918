export const localizations: {
    [language in 'NL' | 'EN']: {
        [field: string]: string;
    };
} = {
    NL: {
        closed_indefinitely: 'Vandaag gesloten',
        closed_indefinitely_since_16: 'Gesloten sinds 16:00',
        closed_indefinitely_since_17: 'Gesloten sinds 17:00',
        closed_indefinitely_since_18: 'Gesloten sinds 18:00',
        closed_indefinitely_since_19: 'Gesloten sinds 19:00',
        closed_indefinitely_since_20: 'Gesloten sinds 20:00',
        full_and_closed: 'Tijdelijk gesloten',
        full_garantuee_ticket: 'Tijdelijk alleen garantieticket',
        full: 'Wachtrij gesloten',
        stopped: 'Gesloten',
        closed: 'Gesloten',
        not_operational: 'In onderhoud',
        default: 'Gesloten',
        openingTime: 'Opent om {time}',
        trains: ' van de ',
        park_hours_title: 'Vandaag is Walibi Holland',
        park_hours: 'open van {open} tot {close}',
        park_hours_from: 'open van',
        park_hours_to: ' tot ',
        park_hours_closed: 'gesloten',
        fast_lane_catch_phrase: 'Verkort je wachttijd via',
        fast_lane_source: 'walibifastlane.nl · Walibi-app',
        fast_lane_exclusion_warning: 'Niet inbegrepen bij Fast Lane'
    },
    EN: {
        closed_indefinitely: 'Closed today',
        closed_indefinitely_since_16: 'Closed since 16:00',
        closed_indefinitely_since_17: 'Closed since 17:00',
        closed_indefinitely_since_18: 'Closed since 18:00',
        closed_indefinitely_since_19: 'Closed since 19:00',
        closed_indefinitely_since_20: 'Closed since 20:00',
        full_garantuee_ticket: 'Temporarily only garantuee ticket',
        not_operational: 'In maintenance',
        full_and_closed: 'Closed temporarily',
        stopped: 'Closed',
        closed: 'Closed',
        full: 'Queue closed',
        default: 'Closed',
        openingTime: 'Opens at {time}',
        trains: ' of the ',
        virtual_queue: 'This is a test banner',
        park_hours_title: 'Today Walibi Holland is',
        park_hours_from: 'open from',
        park_hours_to: ' to ',
        park_hours_closed: 'closed',
        fast_lane_catch_phrase: 'Shorten your waiting time via',
        fast_lane_source: 'walibifastlane.nl · Walibi-app',
        fast_lane_exclusion_warning: 'Not included in Fast Lane'
    }
};
