import moment from 'moment';
import React from 'react';
import { FaRegClock } from 'react-icons/fa';

export const Clock = ({ showIcon = true, fontSize = 'text-5xl' }: { showIcon?: boolean; fontSize?: string }) => {
    return (
        <span className={`flex items-center font-avenir_black ${fontSize}`}>
            <FaRegClock className="mr-2" />
            {moment().format('HH')}
            <span className="animate-blink">:</span>
            {moment().format('mm')}
        </span>
    );
};
