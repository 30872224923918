import { ParkCalendarDay } from '../types';

export const parkCalendar: {
    [date: string]: ParkCalendarDay;
} = {
    '2025-03-28': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-05': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-06': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-11': { openingTime: '10:00', closingTime: '17:00' },
    '2025-04-12': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-13': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-16': { openingTime: '10:00', closingTime: '17:00' },
    '2025-04-17': { openingTime: '10:00', closingTime: '17:00' },
    '2025-04-18': { openingTime: '10:00', closingTime: '17:00' },
    '2025-04-19': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-20': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-21': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-22': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-23': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-24': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-25': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-26': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-27': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-28': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-29': { openingTime: '10:00', closingTime: '18:00' },
    '2025-04-30': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-01': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-02': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-03': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-04': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-05': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-07': { openingTime: '10:00', closingTime: '17:00' },
    '2025-05-08': { openingTime: '10:00', closingTime: '17:00' },
    '2025-05-09': { openingTime: '10:00', closingTime: '17:00' },
    '2025-05-10': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-11': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-17': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-18': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-21': { openingTime: '10:00', closingTime: '17:00' },
    '2025-05-22': { openingTime: '10:00', closingTime: '17:00' },
    '2025-05-23': { openingTime: '10:00', closingTime: '17:00' },
    '2025-05-24': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-25': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-26': { openingTime: '10:00', closingTime: '17:00' },
    '2025-05-27': { openingTime: '10:00', closingTime: '17:00' },
    '2025-05-28': { openingTime: '10:00', closingTime: '17:00' },
    '2025-05-29': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-30': { openingTime: '10:00', closingTime: '18:00' },
    '2025-05-31': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-01': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-04': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-05': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-06': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-07': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-08': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-09': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-11': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-12': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-13': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-14': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-15': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-18': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-19': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-20': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-21': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-22': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-25': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-26': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-27': { openingTime: '10:00', closingTime: '17:00' },
    '2025-06-28': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-29': { openingTime: '10:00', closingTime: '18:00' },
    '2025-06-30': { openingTime: '10:00', closingTime: '17:00' },
    '2025-07-01': { openingTime: '10:00', closingTime: '17:00' },
    '2025-07-02': { openingTime: '10:00', closingTime: '17:00' },
    '2025-07-03': { openingTime: '10:00', closingTime: '17:00' },
    '2025-07-04': { openingTime: '10:00', closingTime: '17:00' },
    '2025-07-05': { openingTime: '10:00', closingTime: '18:00' },
    '2025-07-06': { openingTime: '10:00', closingTime: '18:00' },
    '2025-07-07': { openingTime: '10:00', closingTime: '18:00' },
    '2025-07-08': { openingTime: '10:00', closingTime: '18:00' },
    '2025-07-09': { openingTime: '10:00', closingTime: '18:00' },
    '2025-07-10': { openingTime: '10:00', closingTime: '18:00' },
    '2025-07-11': { openingTime: '10:00', closingTime: '18:00' },
    '2025-07-12': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-13': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-14': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-15': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-16': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-17': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-18': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-19': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-20': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-21': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-22': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-23': { openingTime: '10:00', closingTime: '22:00' },
    '2025-07-24': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-25': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-26': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-27': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-28': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-29': { openingTime: '10:00', closingTime: '19:00' },
    '2025-07-30': { openingTime: '10:00', closingTime: '22:00' },
    '2025-07-31': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-01': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-02': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-03': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-04': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-05': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-06': { openingTime: '10:00', closingTime: '22:00' },
    '2025-08-07': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-08': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-09': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-10': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-11': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-12': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-13': { openingTime: '10:00', closingTime: '22:00' },
    '2025-08-14': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-15': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-16': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-17': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-18': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-19': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-20': { openingTime: '10:00', closingTime: '22:00' },
    '2025-08-21': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-22': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-23': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-24': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-25': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-26': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-27': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-28': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-29': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-30': { openingTime: '10:00', closingTime: '19:00' },
    '2025-08-31': { openingTime: '10:00', closingTime: '19:00' },
    '2025-09-05': { openingTime: '10:00', closingTime: '17:00' },
    '2025-09-06': { openingTime: '10:00', closingTime: '18:00' },
    '2025-09-07': { openingTime: '10:00', closingTime: '18:00' },
    '2025-09-13': { openingTime: '16:00', closingTime: '0:00' },
    '2025-09-14': { openingTime: '10:00', closingTime: '18:00' },
    '2025-09-19': { openingTime: '10:00', closingTime: '17:00' },
    '2025-09-20': { openingTime: '10:00', closingTime: '18:00' },
    '2025-09-21': { openingTime: '10:00', closingTime: '18:00' },
    '2025-09-27': { openingTime: '10:00', closingTime: '18:00' },
    '2025-09-28': { openingTime: '10:00', closingTime: '18:00' }
};
