import { useQuery } from 'react-query';
import axios from 'axios';
import { QSmartRide, Ride, RideStateType } from '../types';
import { endpoints } from '../data/endpoints';

const dummyStates: RideStateType[] = [
    'open',
    'open',
    'open',
    'open',
    'open',
    'open',
    'open',
    'open',
    'closed_indefinitely',
    'full_and_closed',
    'full'
];

type SortMode = 'waitTime' | 'waitTimeReversed' | 'name';

const fetchRideData = async (
    isStaging: Boolean,
    isTestMode: Boolean,
    sortMode: SortMode,
    endpoint: string,
    skipNotOperational: boolean = false,
    tags: string[]
) => {
    const { data } = await axios({
        url: endpoints[endpoint][isStaging ? 'staging' : 'production'],
        method: 'GET',
        headers: { accept: 'application/json' }
    });

    let rides: Ride[] = [];
    data.forEach((d: QSmartRide) => {
        if (tags.length > 0 && !tags.some((tag) => d['tags'].includes(tag))) return;
        // if (skipNotOperational && d['state'] === 'not_operational') return;
        let waitTimeMins = d.minWait / 60;
        let ordinalWeight = waitTimeMins;

        let state = d['state'];
        if (isTestMode) {
            waitTimeMins = 5 * Math.floor(Math.random() * 20);
            state = dummyStates[Math.floor(Math.random() * dummyStates.length)];
        }

        const dd = sortMode === 'waitTimeReversed' ? -1 : 1;

        // Probably should do this with an ordinal weight

        if (state !== 'open') ordinalWeight = 10000 * dd;
        if (state === 'full') ordinalWeight = 99996 * dd;
        if (state === 'full_and_closed') ordinalWeight = 99997 * dd;
        if (state === 'closed_indefinitely') ordinalWeight = 99998 * dd;
        if (state === 'not_operational') ordinalWeight = 99999 * dd;

        if (d['tags'].includes('wh_screen_pinned')) ordinalWeight = 0;

        rides.push({
            id: d['id'],
            throughput: d['throughput'],
            name: d['name'],
            ordinalWeight,
            nameLocalized: d.localizations.find((l) => l.culture === 'nl-NL')?.name || d['name'],
            location: d['info']['visible.entrance'],
            waitTimeMins,
            state,
            tags: d['tags'],
            openingTime: d.openingTime,
            img: endpoints[endpoint].cache.replace('__url__', d['id'])
        });
    });

    if (sortMode === 'waitTime') {
        rides.sort((a, b) => {
            if (a.ordinalWeight < b.ordinalWeight) return -1;
            else if (a.ordinalWeight > b.ordinalWeight) return 1;
            else return a.name.localeCompare(b.name);
        });
    } else if (sortMode === 'waitTimeReversed') {
        rides.sort((a, b) => {
            if (a.ordinalWeight > b.ordinalWeight) return -1;
            else if (a.ordinalWeight < b.ordinalWeight) return 1;
            else return a.name.localeCompare(b.name);
        });
    } else {
        rides.sort((a, b) => {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
        });
    }

    console.log(rides);

    return rides;
};

const useWaitingTimes = ({
    isTestMode,
    isStaging,
    sortMode = 'name',
    endpoint = 'cawh',
    skipNotOperational = true,
    tags = []
}: {
    isTestMode: Boolean;
    isStaging: Boolean;
    sortMode?: SortMode;
    endpoint?: string;
    skipNotOperational?: boolean;
    tags?: string[];
}) => {
    const query = useQuery('waitingTimes', () => fetchRideData(isStaging, isTestMode, sortMode, endpoint, skipNotOperational, tags), {
        refetchInterval: 15000
    });
    return query;
};

export { useWaitingTimes };
