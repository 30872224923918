import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { parkCalendar } from '../../../../../data/parkCalendar';
import { getLocalization } from '../../../../../utils/getLocalization';
import { LocalizationContext } from '../../../../../context/LocalizationContext';
import { ParkCalendarDay } from '../../../../../types';
import { Clock } from '../../../../Clock';

export const WaitTimeScreenFooter = () => {
    const currentLanguage = useContext(LocalizationContext);

    return (
        <div className="grid grid-cols-[720px,100px,720px] absolute bottom-[65px] items-center w-full max-w-[1540px] font-avenir ">
            <div className="">
                <div className="flex justify-between">
                    <Clock />
                    <ParkHours />
                </div>
            </div>
            <div className="h-full w-1 bg-slate-300 mx-auto rounded-xl"></div>
            <div className="flex justify-between relative">
                <div>
                    <p className="text-3xl text-gray-500">{getLocalization('fast_lane_catch_phrase', currentLanguage)}</p>
                    <p className="font-avenir_black text-[40px]" style={{ lineHeight: '42px' }}>
                        {getLocalization('fast_lane_source', currentLanguage)}
                    </p>
                </div>
                <img src="/logo.png" className="h-32 absolute right-[-40px] top-[-40px]" alt="logo" />
            </div>
        </div>
    );
};

const ParkHours = () => {
    const day = moment().format('YYYY-MM-DD');
    const [hours, setHours] = useState<ParkCalendarDay | undefined>(parkCalendar[day]);

    useEffect(() => {
        setHours(parkCalendar[day]);
    }, [day]);

    const currentLanguage = useContext(LocalizationContext);
    return (
        <div>
            <>
                <p className="text-3xl text-right text-gray-500">{getLocalization('park_hours_title', currentLanguage)}</p>
                {hours && hours.openingTime ? (
                    <p className="text-3xl text-right text-gray-500">
                        {getLocalization('park_hours_from', currentLanguage)}{' '}
                        <span className="font-avenir_black text-gray-900">{hours.openingTime}</span>{' '}
                        {getLocalization('park_hours_to', currentLanguage)}{' '}
                        <span className="font-avenir_black text-gray-900">{hours.closingTime}</span>
                    </p>
                ) : (
                    <p className=" text-3xl text-right font-avenir_black">{getLocalization('park_hours_closed', currentLanguage)}</p>
                )}
            </>
        </div>
    );
};
